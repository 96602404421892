<template>
    <div class="container-fluid mt-5 pt-5">
        <BasePageBreadCrumb :pages="pages" title="itens do tipo" :menu="menu"/>
    
        <div class="container-fluid qsuite-c-fluid-0">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-8">
                                <div class="form-group d-flex justify-content-end">
                                    <input type="search" class="form-control campo-procurar" placeholder="Procurar" v-model="search">
                                    <button type="submit" class="btn btn-info" @click="fetchTypeItens">Buscar</button>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4 d-none d-lg-block">
                                <div class="form-group d-flex justify-content-end">
                                    <router-link :to="{name: 'ConfigTypeItemNew'}">
                                        <button type="submit" class="btn btn-dark"><i class="far fa-file-alt mr-2"></i>Novo item</button>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="card-list" v-for="item in types" :key="item.id"> 
                            <div class="card-body-list"> 
                                <div class="col-12 p-0 align-self-center">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-2 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                                <img :src="item.image" v-if="item.image" alt="user" class="rounded-circle card-body-list-img" />
                                            </div>
                                            <div class="col-8 col-md-7 col-lg-10 p-0 order-3 align-self-center">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-12 pad-content-list">
                                                            <h6 class="text-truncate card-body-list-title" v-if="item.identification_name==''">{{ item.name }}</h6>
                                                            <h6 class="text-truncate card-body-list-title" v-if="item.identification_name!=''" >{{ item.identification_name }}</h6>
                                                        </div>
                                                        <div class="col-12 text-muted pad-content-list">
                                                            <h6 class="text-truncate empreendi">{{ item.description }}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">
                                                 <b-link :to="{name: 'ConfigTypeItemEdit', params: {id: item.id}}" class="btn btn-dark rounded-circle btn-circle font-20 card-body-list-cta" >+</b-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="col-12 text-center p-4" v-if="types.length == 0">
                        Nenhum item do tipo encontrado
                    </div>
                </div>
                <div class="col-12">
                    <BasePagination
                        v-if="types.length > 0"
                        :totalPages="pagination.totalPages"
                        :activePage="pagination.currentPage"
                        @to-page="toPage"
                        @per-page="perPage" />
                </div>
            </div>
        </div>
    </div>
    </template>
    <script>
    import KitConfigService from "@/services/resources/KitConfigService";
    const service = KitConfigService.build();
    
    export default {
      data() {
        return {
          name: null,
          types: [],
          search: '',
          tipologies: [],
          pagination: {
            totalPages: 1,
            currentPage: 1,
            perPage: 10,
            itens: 0
          },
          pages:[
            {
                name: 'dashboard',
                to: 'Dashboard'
            },
            {
                name: 'imóveis',
                to: 'Enterprise'
            }
          ],
          menu:[
            {
                name: 'Imóveis',
                to: 'Enterprise'
            }
          ]
        };
      },
      methods: {
        toPage(page) {
            this.pagination.currentPage = page;
            this.fetchTypeItens();
        },
        perPage(qtd) {
            this.pagination.currentPage = 1;
            this.pagination.perPage = qtd;
    
            this.fetchTypeItens();
        },
        fetchTypeItens(){
            
            let data = {
                page: this.pagination.currentPage,
                per_page: this.pagination.perPage,
                type: 'PERSONALIZATION_TYPE_ITEM'
            };
    
            if(this.search){
                data['search'] = this.search;
            }
    
            service
              .search(data)
                .then(resp => {
                    this.types = resp.data;
                    this.pagination.totalPages = resp.last_page;
                    this.pagination.itens = resp.total;
                })
                .catch(err => {
                  console.log(err)
                })
        }
    
      },
      mounted() {
          this.fetchTypeItens();
      },
    };
    </script>
    <style scoped lang="scss">
    .bg{
      background: #fff;
      padding: 10px;
      border: 1px #ddd solid;
      border-radius: 5px;
    }
    </style>